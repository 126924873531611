@import "_main";

//------------------------------------------------------------------------------
// JKU-LAYOUT
.fonts-loaded body {
  font-family: "gothambook", sans-serif; // !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "gothambold", "Arial", sans-serif;
}

a:not(.btn):not(.navbar-brand):not(.nav-link):not(.dropdown-item):not(.close):not(.fc-event):not(.ak-number-edit-pencil) {
  color: #000;
  font-family: "gothambold", "Arial", sans-serif;

  &:hover,
  &:focus {
    color: #7d828c;
    border-color: #7d828c;
  }

  &:not(.non-formated):not(.fc-daygrid-day-number) {
    border-bottom: rem(1) solid #000;
  }
}

.icon-table a {
  border: none !important; // stylelint-disable-line declaration-no-important
}

.select2-container--default {
  //@extend .custom-select;
  padding: 0;
  border: rem(2) solid #7d828c;
  border-radius: 0;
  //height: var(--x-input-height);
  &:hover,
  &:focus {
    border-width: rem(2);
    border-color: #000;
    box-shadow: none;
  }
}

.select2-selection--single,
.select2-selection__rendered {
  height: 100%; // !important;
  border: 0; // !important;
}

.select2-selection__placeholder {
  vertical-align: middle;
}

.card {
  border: rem(3) solid #b1b4ba;
}

.color-header {
  height: rem(8);
  background: #000;
}

.navbar-x-net .navbar-brand,
.navbar-x-net .navbar-nav .nav-link {
  font-family: "gothambold", sans-serif;
}

.btn {
  font-family: "gothambold", sans-serif;
  text-transform: uppercase;
  border-width: rem(3);
}

.alert,
.btn,
.card,
.fc .fc-button,
.dropdown-menu,
.modal-content,
.input-group > .form-control:not(:last-child),
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-radius: 0;
}

.custom-file-label,
.form-control,
.form-control[list],
.custom-select,
.custom-date {
  border: rem(2) solid #7d828c;
  border-radius: 0;

  &:hover,
  &:focus {
    border-width: rem(3);
    border-color: #000;
    box-shadow: none;
  }
}

.btn:focus,
.fc .fc-button:focus,
.focus.btn,
.fc .focus.fc-button {
  box-shadow: none;
}

label,
.label {
  font-family: "gothambold", sans-serif;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-primary {
  &:hover,
  &:focus {
    color: #000;
    border-color: #000;
    background-color: #fff;
  }
}

.btn-secondary {
  color: #000;
  border-color: #000;
  background-color: #fff;

  &:hover,
  &:focus {
    color: #fff;
    border-color: #000;
    background-color: #000;
  }
}

.alert-danger {
  color: #fff;
  background-color: #d95c4c;
  border-color: #d95c4c;
}

.dropdown-menu {
  box-shadow: none;
  border: rem(3) solid #000;
}

.dropdown-item {
  &.active {
    font-family: "gothambold", sans-serif;

    &,
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  &:hover,
  &:focus,
  &.active:focus,
  &.active:hover,
  &:active:focus,
  &:active:hover {
    color: inherit;
    background-color: #e0e1e3;
  }
}

.modal-title {
  text-transform: uppercase;
}

.modal-header::after,
.navbar-nav .active::after {
  content: none;
}

.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-width: rem(2);
  border-color: #000;
}

.table,
.custom-control-label::after {
  border: rem(3) solid #000;
}

.table {
  > thead,
  > tbody {
    border-bottom: rem(3) solid #000;
  }
}

body {
  background-color: green;
}

.dropdown-item {
  white-space: normal;
}

.navbar-header {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint);
    $infix: breakpoint-infix($next);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        & ~ main .table {
          --x-table-fixed-header-offset: #{rem(64)};
        }

        .navbar-nav {
          .dropdown-item {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

table {
  --x-table-fixed-header-offset: 0;

  .lt-date {
    background-color: $gray-200;
  }

  .sticky-col {
    @extend .text-nowrap;
    position: sticky;
    background-color: white;
    z-index: 999;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}

.scroll-fieldsets {
  .row.sm-gutters {
    max-height: rem(200);
    overflow-y: auto;
    box-shadow: inset 0 -1rem 0.5rem -1rem;
  }
}

.custom-control.custom-radio .custom-control-label::after {
  border-radius: 50%;
}

.select2 {
  .selection {
    .select2-selection {
      border: none;
    }
  }
}

.modal-input-size {
  .form-group {
    input {
      height: 2.69rem;
    }

    .select2 {
      height: 2.69rem;
    }
  }
}

.fonts-loaded h4,
.fonts-loaded .h4,
.fonts-loaded h5,
.fonts-loaded .h5,
.fonts-loaded legend:not(.label) {
  font-weight: 700 !important; // stylelint-disable-line declaration-no-important
}
