// stylelint-disable no-empty-source

@import "_main";

// Gulp looks for variables in the following files in the specified order.
// 1. In the NodeJS package X-Net Rocket SCSS _variables.scss
// 2. In the project.json in the project root
// 3. In the custom_variables.scss
@import "_custom_variables";
@import "fonts";
@import "jku_layout";


//------------------------------------------------------------------------------
// LOGIN-AS
.fixed_alert_container {
  background-image: linear-gradient(to bottom, #FCF8E3 0%, #F8EFC0 100%);
  background-repeat: repeat-x;
  border-color: #F5E79E;
  font-size: rem(15);
  padding: rem(9);
}

.svg-inline {
  height: 1.25em;
  vertical-align: -0.225em;
  width: 1.25em;
}


//------------------------------------------------------------------------------
// Zebra DatePicker
.Zebra_DatePicker {
  z-index: 10000;
}

.Zebra_DatePicker_Icon_Wrapper {
  width: 100% !important; // stylelint-disable-line declaration-no-important
}

//------------------------------------------------------------------------------
// Fullcalendar
.calendar-element-title {
  padding-left: rem(5);
  padding-right: rem(5);
}

.calendar-element-week-header {
  display: flex;
  overflow: inherit;
  text-overflow: ellipsis;
}

.calendar-element-week-theme {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: normal;
}

.calendar-element-week-footer {
  flex-grow: 0;
  text-overflow: ellipsis;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip .tooltip-inner {
  background-color: #FFF;
  border: rem(1) solid black;
  color: #0a0c0d;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  .arrow {
    &::before {
      border-bottom-color: black;
    }
  }
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: black;
    }
  }
}

.ak-number-edit-pencil-svg {
  fill: black;
  text-decoration-color: black;
}

.ak-number-edit-text {
  text-decoration: underline;
}

.module-circle {
  border-radius: 50%;
  width: rem(15);
  height: rem(15);
}

.tooltip-wide {
  max-width: rem(1000);
}


.w-10 {
  width: 10%;
}

.postion-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.l-50 {
  left: 50%;
}

.l--50 {
  left: -50%;
}

.ideal_semester_filter_select {
  right: rem(150);
  width: 10rem;
  height: rem(42);
}

.check_calendar_container {
  display: flex;
  justify-content: center;
  margin-bottom: -3rem;
}

#check_calendar_button {
  display: flex;
  @extend .align-items-center;
  width: 8%;
}

.general_comment_container {
  display: flex;
  justify-content: center;
  margin-bottom: -3rem;
}

#edit_comment_button {
  display: flex;
  @extend .align-items-center;
  width: 13%;
}

#snapshot_calendar_button {
  display: flex;
  @extend .align-items-center;
  width: 8%;
}

.loader {
  z-index: 999999;
  width: rem(45);
  height: rem(45);
  border: rem(10) solid #f3f3f3;
  border-radius: 50%;
  border-top: rem(10) solid black;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.icon-event {
  fill: black;
}

.event-header {
  justify-content: space-between;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}



.check-calendar-course-class {
  width: rem(150);
  display: inline-block;
}


.calendar_module_display {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .calendar_module_icon {
    width: rem(30);
    max-height: 3rem;
  }

  .calendar_module_display_span {
    font-size: rem(20);
  }

  #calendar_module_tooltip {
    max-height: 3rem;
  }
}

.fc-timegrid-event .fc-event-main {
  padding: 0;
}

.fc-list-day-side-text {
  float: left !important; // stylelint-disable-line declaration-no-important
  margin-left: rem(5);
}

.drag-line {
  position: absolute;
  height: rem(1);
  background-color: red;
  pointer-events: none;
  z-index: 9999; /* Make sure the line is on top of the calendar */
}

.final-release-hint {
  color: red;
}

.fc .fc-bg-event {
  opacity: 1;
  font-weight: bold;
}

.fc .fc-bg-event:not(.fc-event-start) {
  color: transparent;
}

.check_calendar_modal {
  .modal-body {
    overflow: scroll;
    min-height: 80vh;
    max-height: 80vh;
    margin-bottom: 2rem;
  }
}

#id_semester_type_check_calendar_modal {
  min-width: 8rem;
}

hr.custom-tooltip {
  border-top: rem(1) solid;
  border-bottom: none;
}

.modal-error-xl {
  .modal-content {
    max-width: 90%;
  }
}


.fc-timegrid-slots {
  position: relative;
  z-index: 2;

  tbody {
    $table-height: 100;
    $j: 0;
    $c: rgba(255, 255, 255, 0.26);
    $g: rgba(211, 211, 211, 0.54);
    $bc: $c;

    @for $i from 1 through $table-height {
      tr:nth-child(#{$i}) {
        @if $i == 3 {
          $bc: $g;
          $j: 0;
        }
        @if $j == 3 {
          @if $bc == $c {
            $bc: $g;
          } @else {
            $bc: $c;
          }
        } @else if $j == 6 {
          @if $bc == $c {
            $bc: $g;
          } @else {
            $bc: $c;
          }
        } @else if $j == 7 {
          @if $bc == $c {
            $bc: $g;
          } @else {
            $bc: $c;
          }
          $j: 0;
        }
        background-color: $bc; // 3 Zeilen weiß
        $j: $j + 1;
      }
    }
  }
}

.fc .fc-timegrid-slot-minor {
  border: rem(1) solid var(--fc-border-color);
}
