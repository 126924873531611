@font-face {
  font-family: "gothamblack";
  src: url("../fonts/gotham/gotham-black-webfont.woff2") format("woff2"),
    url("../fonts/gotham/gotham-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("../fonts/gotham/gothambook-webfont.woff2") format("woff2"),
    url("../fonts/gotham/gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold";
  src: url("../fonts/gotham/gotham-bold-webfont.woff2") format("woff2"),
    url("../fonts/gotham/gotham-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
